import gql from "graphql-tag";


export const CHECK_EMAIL = gql`
    query CheckEmailAndAccountId($accountId: String!) {
        minters(where: {accountId: {_eq: $accountId}}) {
            group
            qrcode
            claimed
            accountId
        }
    }
`;

export const SET_EMAIL_CLAIMED = gql`
    mutation SetEmailClaimed($email: String!) {
        update_minters(where: {email: {_eq: $email}}, _set: {claimed: "TRUE"}){
            affected_rows
        }
    }
  `