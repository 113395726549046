import React, {useState, useEffect} from 'react'
import {
  Paper,
  Stack,
  Chip,
  Typography,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tabs,
  Tab,
  Box
} from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress';
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';
import rarityData from '../../assets/hgpData/rarityData.json'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
          <Typography>{children}</Typography>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    'style': {width:'100%'}
  };
}

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${
          props.value
        }%`}</Typography>
      </Box>
    </Box>
  );
}

function generateRarityData(json,rarityData,setPackRarity, setRarity){
  const newJSON = json;
  let attributeRarity = 0;
  newJSON?.attributes
    .forEach(({trait_type, value}) => {
      let amount = parseInt(Number.parseFloat((rarityData.attributes[trait_type][value])).toFixed(2));
      switch(trait_type) {
        case 'extras': 
          amount = amount * .15
        break;
        case 'forehead-h':
          amount = amount * .2
        break;
        case 'background':
          amount = amount 
        break;
      }
      attributeRarity += amount
    })
  let packType = json.properties.extras[0] == undefined ? 'none' : json.properties.extras[0]
  let packRarity = parseInt(Number.parseFloat(rarityData.extras[packType]).toFixed(2));
  let totalRarity = Number.parseFloat(attributeRarity).toFixed(2)
  
  // let median = Number.parseFloat((totalRarity/10));
  setPackRarity(packRarity);
  setRarity(totalRarity);
}

export default function Punk({NFTId}) {
  
  const [meta, setMeta] = useState()
  const [packRarity, setPackRarity] = useState()
  const [rarity, setRarity] = useState()
  const [src, setSrc] = useState()
  const [loading, setLoading] = useState(true)
  const [imgloading, setImgLoading] = useState()
  const [tab, setTab] = React.useState(0);
  const handleTab = (event, newValue) => {
    setTab(newValue);
  };
  
  useEffect(async () => {
    axios
    .get('https://mainnet-public.mirrornode.hedera.com/api/v1/tokens/0.0621100/nfts/' + NFTId)
    .then(async (hederaJSON) => {
      const decodedStringAtoB = atob(hederaJSON.data.metadata);
      const CID = decodedStringAtoB.split('ipfs://')[1];
      const response = await fetch(`https://cloudflare-ipfs.com/ipfs/${CID}`)
      const json = await response.json()
      setMeta(json)
      setLoading(false)
      console.log(json)
      const imgCID = json.image.split('ipfs://')[1];
      setSrc(`https://cloudflare-ipfs.com/ipfs/${imgCID}`);
      setImgLoading(false)
      generateRarityData(json, rarityData, setPackRarity, setRarity)
    });
  }, [NFTId])

  return (
    loading ?  <div style={{width:'100px',margin: '50px auto 50px'}}><CircularProgress color="primary" style={{width:'100px', height:'100px', margin: '0 auto'}} /></div>  : <>   
    <div style={{maxWidth: 425, width: '100%', position: 'relative'}} padding={24}>
      <div style={{textAlign: 'center', background: '#303030', height: 300}}>
        {imgloading ? <div style={{width:'100px',margin: '50px auto 50px', zIndex:'1000'}}><CircularProgress color="primary" style={{width:'100px', height:'100px', margin: '0 auto'}} /></div> :
          <img
          alt={meta?.description}
          src={src}
          height={260}
          width={260}
          style={{border: '4px solid #121212', margin: 40}}
        />
        }
        
      </div>
      <div style={{marginTop: -130}}>
        <Paper>
          <div style={{height: 150}} />
          {/* Extras */}
          <Stack direction='row' justifyContent='space-around' spacing={1}>
            {meta?.properties?.extras?.map((extra) => (
              <Chip color='primary' label={extra} />
            ))}
            {meta?.attributes
              ?.filter(({trait_type, value}) => trait_type === 'extras' && value !== 'none')
              .map(({value: extra}) => (
                <Chip color='primary' label={extra} />
              ))}
          </Stack>
          {/* Mint stats */}
          <Typography variant='h6' textAlign='center'style={{margin: '20px 0 0'}} fontWeight='bold' color='primary'>
            {meta?.name} 
          </Typography>
          <br />
          <Typography variant='h6' textAlign='center' fontWeight='bold' color='primary'>
            Catalog Stats
          </Typography>
          <br />
          <Stack
            direction='row'
            divider={<Divider orientation='vertical' flexItem />}
            justifyContent='space-around'
          >
            <div>
              # {meta?.properties?.edition?.set}
              <br /> set
            </div>
            <div>
              # {meta?.properties?.edition?.drop}
              <br /> drop
            </div>
            <div>
              # {meta?.properties?.edition?.pack}
              <br /> pack
            </div>
          </Stack>
          {/* Rarity traits */}
          {/* <br />
          <div style={{margin: '0 auto', width: '100%', textAlign: 'center'}}>
             Pack Rarity<br/>{packRarity} out of 512<br />
          </div> */}
          <br />
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleTab} aria-label="basic tabs example">
              <Tab label="Attributes & Rarity" {...a11yProps(0)} />
            </Tabs>
          </Box>
          <TabPanel style={{width:'100%'}}value={tab} index={0}>
            <TableBody style={{width:'100%'}}>
              {meta?.attributes
                .map(({trait_type, value}, index) => (
                    <TableRow style={{padding: '20px 0px', width:'100%'}} key={index}>
                      <TableCell component='th' fontWeight='bold'>
                      <Typography variant='span' textAlign='center'style={{margin: '20px 0 0'}} color='#808080'>
                        {trait_type}
                      </Typography>
                      <br />
                      {value}
                      </TableCell>
                      <TableCell align='right'>
                        <LinearProgressWithLabel style={{width:'75px'}} value={Number.parseFloat((rarityData.attributes[trait_type][value]/512)*100).toFixed(2)} />
                      </TableCell>
                    </TableRow>
                ))
              }
            </TableBody>
          </TabPanel>
        </Paper>
      </div>
    </div>
    </>
  )
}
