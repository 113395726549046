const jwt = require("jsonwebtoken");


const createJwtToken = body => jwt.sign(body, process.env.REACT_APP_JWT_SECRET);

export default (userId) =>
  createJwtToken({
    "https://hasura.io/jwt/claims": {
      "x-hasura-allowed-roles": ["application"],
      "x-hasura-default-role": "application",
      "x-hasura-user-id": userId
    }
  }); 