import {useEffect, useState, useContext} from 'react'
import gql from "graphql-tag";
import {
  Paper,
  TextField, 
  Button,
  Box,
  Tab,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  Checkbox
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab'

import {QRCode} from './QRCode'
import TMTContext from '../context/tmt-context'
import Punk from './Punk'
import { CHECK_EMAIL } from "../gql/Query"
import {useMutation, useQuery} from "@apollo/client"
import { createAppJwtToken } from '../scripts/JWTtokenService';

export const Dashboard = () => {
    // Read context of signed in Xact wallet creds
    const context = useContext(TMTContext)
    const email = context?.email;
    const accountId = context?.accountId;
    const [groupNum, setGroupNum] = useState(0)
    const { loading, error, data } = useQuery(CHECK_EMAIL, {
      variables: { accountId }
    });
    

    useEffect(async () => {
      if (!loading) {
        let groupNum = Math.ceil(parseInt(data?.minters[0]?.group) / 10)
        setGroupNum(groupNum)
      } 
    }, [loading])
  
    return( 
      <div>
        {loading ?  <div style={{width:'100px',margin: '50px auto 50px'}}><CircularProgress color="primary" style={{width:'100px', height:'100px', margin: '0 auto'}} /></div>  : <>
        <Box md={{ width: '100%', typography: 'body1' }}>
              <br />
              <div style={{width:'300px',margin: '0 auto'}}>
              <Grid item xs={12}>
                  {
                    data?.minters.length >= 1 ?
                        <>
                        {/* <h4 style={{width:"300px", textAlign:"center"}}> Scan the QR code below with XACT wallet to claim your <span style={{color:'#8159EF'}}>HGraph Punk</span></h4>
                        <h4 style={{width:"300px", textAlign:"center"}}> IF you get <span style={{color:'red'}}>[object, Object]</span> error:<br /> wait 30 seconds, and try again</h4> */}
                        <h4 style={{width:"300px", textAlign:"center"}}> Minting is disabled until XACT SDK is back online. </h4>
                       
                        {/* {
                              <img style={{width:"300px", height:"300px"}} src={require(`../assets/qrCodes/hgp_punks_drop_1_${data.minters[0].qrcode}.jpg`).default} />
                           
                        } */}
                        </>
                      : 
                      <>
                        <h4 style={{width:"300px"}}><span style={{color:'red'}}>{email}</span> <br />or XACT Wallet ID: <span style={{color:'red'}}>{accountId}</span> <br />seems to be different than what you submitted on our minting form, or doesn't seem to be on the Drop1 Minting List<br /><br />Please refresh and try again with the email and XACT wallet Id you signed up with for the <span style={{color:'purple'}}>HGraph Punk</span> minting email.</h4>
                      </>
                  }
              </Grid>
              </div>
              <br />
              <br />
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 3 }}>
                <div style={{margin: '20px auto', paddingLeft:'24px'}}>
                  <Grid item xs={12}>
                        <Punk NFTId={data?.minters[0]?.qrcode} />
                  </Grid>
                  </div>
              </Grid>
        </Box>
        </>
      }
      </div>
    );
}