import * as React from 'react';
import {useEffect, useState} from 'react'
import Container from '@mui/material/Container';
import {Button, Box, TextField, Typography, Modal} from '@mui/material';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import {Toolbar, FormGroup, FormControlLabel, Switch} from '@mui/material';
import {Dashboard} from './components/Dashboard';
import {Loading} from './components/Loading';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {TMTProvider} from './context/tmt-context';
import logo from './assets/title_card.png';
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink } from '@apollo/client';
import gql from "graphql-tag";
import createAppJwtToken from "./scripts/JWTtokenService"

const createApolloClient = (token) => {
  return new ApolloClient({
    link: new HttpLink({
      uri: process.env.REACT_APP_HASURA_URL,
      headers: {
        authorization: `Bearer ${token}`
      }
    }),
    cache: new InMemoryCache(),
  });
};

function Copyright() {
  return (
    <>
    <br />
    <br />
      <Typography variant="body2" color="text.secondary" align="center">
        {'Built by '}
        <Link color="inherit" href="https://turtlemoon.io/">
          Turtle Moon
        </Link>{' '}
        {new Date().getFullYear()}. {' '}
        <Link color="inherit" href="https://wallet.xact.ac/">
          Powered by Xact 
        </Link>{' '}
        <br/>
      </Typography>
      <br />
      <br />
    </>
  );
}

export default function App() {
  const [hederaMainnetEnv, setEnv] = React.useState(false);
  const [whitelistEmail, setWhitelistEmail] = React.useState('');
  const [whitelistAccountId, setWhitelistAccountId] = React.useState('');
  const [loginOpen, setLoginOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [xactClient, setXactClient] = React.useState({});
  const [email, setEmail] = React.useState(false);
  const [apollosClient, setApolloClient] = useState(createApolloClient());

  const claimPunk = () => {
    setLoginOpen(false)
    setEmail(whitelistEmail);
    const JWT = createAppJwtToken(whitelistEmail)
    setApolloClient(createApolloClient(JWT))
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    outline: 'none',
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        light: '#757ce8',
        main: '#8159EF',
        dark: '#412C77',
        contrastText: '#efefef',
      },
    },
    TextField: {
      width: '100%'
    }
  });

  return (
    <ApolloProvider client={apollosClient}>
      <ThemeProvider theme={darkTheme}>
        <TMTProvider value={{hederaMainnetEnv:hederaMainnetEnv, email:email,accountId:whitelistAccountId, xactClient: xactClient, setLoading: setLoading}}>
        <AppBar position='static' style={{margin: '0 auto'}}>
          <Toolbar style={{position:'relative', width: '96vw', margin: '0 auto', maxWidth: '1200px'}} > 
            
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{color: 'grey'}}>
              HGraph Punks Flea Market
            </Typography> 
            <Modal
                open={loginOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <img src={logo} style={{position:'absolute', top:'-100px', left:'0px',width:"100%",margin: "0 auto"}} />
             
                  <Typography style={{textAlign:'center', margin:'20px 0'}} id="modal-modal-title" variant="h6" component="h2">
                    Drop 1 Minting Site
                  </Typography>
                    <TextField
                      style={{width:'100%'}}
                      placeholder={"Enter Your Winning Email Address "}
                      value={whitelistEmail}
                      onInput={ e=>setWhitelistEmail(e.target.value)}
                      /> 
                    <br/>
                    <br/>
                    <TextField
                    style={{width:'100%'}}
                    placeholder={"XACT wallet address (0.0.XXXXXX)"}
                    value={whitelistAccountId}
                    onInput={ e=>setWhitelistAccountId(e.target.value)}
                    /> 
                    <br/>
                    <br/>
                    <br/>
                    <Button
                    style={{width:'100%'}}  
                        variant="contained"
                        component="label"
                        disabled={!whitelistEmail || !whitelistAccountId}
                        onClick={() => {claimPunk()}}
                    >
                        See Your HGraph Punk
                    </Button>
                  <br />
                </Box>
            </Modal>
            <Modal
                open={loading}
                aria-describedby="modal-modal-description" 
              >
              <Box sx={style}>
                <div style={{margin: '0 auto', height: '256px', display:'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Loading />
                </div>    
                <Typography style={{textAlign:'center'}} id="modal-modal-title" variant="h6" component="h2">
                  Loading... 
                </Typography>   
              </Box>
            </Modal>
          </Toolbar>
        </AppBar>
        <Container maxWidth="lg">    
          <CssBaseline />
          {email ? <Dashboard /> : ''}
          <Copyright />
        </Container>
        </TMTProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}
